import React from 'react'
import PropTypes from 'prop-types'

const SpecializationBox = (props) => (

    <div className='specialization-page__item item wow fadeInUp'>

        <div className="item__link">
            {props.icon.localFile.publicURL && <img
                type="icon"
                src={props.icon.localFile.publicURL}
                className="item__icon"
                alt=''/>
}
            <h3>{props.title}</h3>

            <div className="item__content" dangerouslySetInnerHTML={{ __html: `${props.content}` }} />
        </div>
    </div>

)
SpecializationBox.propTypes = {
    icon: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
  }

export default SpecializationBox