import React, {Component} from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import {graphql} from "gatsby"
import SpecializationBox from '../components/specialization-box'
import AnimateComponent from '../components/animate-component'
import {Link} from 'gatsby'

class SpecializationsTemplate extends Component {

    render() {

        const specs = this.props.data.allWordpressWpSpecjalizacje.edges;
        return (

            <Layout>
                <SEO title="Oferta | Świecki Kancelaria"/>
                <AnimateComponent styleName="specialization-page" tag='section'>
                    <div className="specialization-page__container">
                        <header className="specialization-page__header wow zoomIn">
                            <div className="specialization-page__title-container">
                                <h1>
                                    Nasza oferta
                                </h1>
                            </div>
                        </header>
                        <div className="specialization-page__items">
                            <div className="specialization-page__item item placeholder"></div>

                            {specs.map(({node}) => (
                            <SpecializationBox
                                key={node.id}
                                title={node.title}
                                content={node.content}
                                icon={node.featured_media}
                                />
                                ))}

                            <div className="specialization-page__item item contact">
                                <div className="item__link">
                                    <h3>Masz pytania?</h3>
                                    <p>Zapraszamy na spotkanie i rozmowę przy kawie.</p>
                                    <div className="link-button">
                                        <Link to="/kontakt/">Skontaktuj się z nami</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AnimateComponent>
            </Layout>
        )
    }
}

export default SpecializationsTemplate

export const query = graphql `
  query{
    allWordpressWpSpecjalizacje( sort: {fields: [menu_order], order: ASC} ) {
        edges {
          node {
            title
            slug
            id
            content
            featured_media{
                localFile {
                    publicURL
                }
            }
          }
        }
      }
  }
`
